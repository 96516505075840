<template>
  <div id="master-data-farm-add">
    
    <!--begin::Form-->
    <ValidationObserver ref="formAdd">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">

    <!--begin::Card-->
      <div class="card card-custom mt-8">

          <!--begin::Header-->
          <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
				          <h3 class="card-label font-weight-bolder text-dark">Informasi Farm</h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Tambah data informasi farm disini</span>
              </div>
          </div>
          <!--end::Header-->

          <div class="card-body">

              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Nama Farm</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Nama Farm" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-1" 
                              label-for="input-farm-1"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              id="input-farm-1"
                              v-model="form.nama"
                              autocomplete="off"
                              class="form-control form-control-solid"
                              required
                              size="lg"
                              placeholder="Nama Farm"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Sistem Kandang</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Sistem Kandang" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-3" 
                              label-for="input-farm-3"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-farm-3"
                              v-model="form.sistem_kandang"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsSistemKandang"
                              placeholder="Silahkan pilih Sistem Kandang"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              
              <!--begin::Form Group-->
              <div class="separator separator-dashed my-5 mt-15"></div>
              <!--begin::Form Group-->
              <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-7">
                      <h5 class="font-weight-bold mb-6">Legalitas Farm:</h5>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Kepemilikan</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Kepemilikan" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-3" 
                              label-for="input-farm-3"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-farm-3"
                              v-model="form.milik"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsKepemilikan"
                              placeholder="Silahkan pilih Kepemilikan"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                          <span class="form-text text-muted">Isi sesuai dengan legalitas kepemilikan farm.</span>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div v-if="kepemilikan != 1">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Pemilik Farm</label>
                    <div class="col-lg-9 col-xl-7">
                        <validationProvider name="Pemilik Farm" rules="required|min:5|max:255" v-slot="{ valid, errors }">
                            <b-form-group
                                id="input-group-farm-4" 
                                label-for="input-farm-4"
                                :invalid-feedback="errors[0]"
                                class="mb-0"
                                :state="valid">
                                <b-form-input
                                id="input-farm-4"
                                v-model="form.hakmilik"
                                class="form-control form-control-solid"
                                autocomplete="off"
                                required
                                size="lg"
                                placeholder="Nama Pemilik Farm"
                                :state="valid"
                                ></b-form-input>
                            </b-form-group>
                            <span class="form-text text-muted">Isi dengan nama  lengkap Perusahaan/Instansi/Perorangan.</span>
                        </validationProvider>
                    </div>
                </div>
              </div>

              <!--begin::Form Group-->
              <div class="separator separator-dashed my-5 mt-15"></div>
              <!--begin::Form Group-->
              <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-7">
                      <h5 class="font-weight-bold mb-6">Penanggung Jawab:</h5>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">NIK PJ</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="NIK PJ" rules="required|numeric|digits:16" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-5" 
                              label-for="input-farm-5"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              id="input-farm-5"
                              v-model="form.pj_nik"
                              class="form-control form-control-solid"
                              autocomplete="off"
                              required
                              size="lg"
                              placeholder="NIK PJ"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                          <span class="form-text text-muted">Pemilik Farm atau pejabat tertinggi pada Farm.</span>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Nama PJ</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Nama PJ" rules="required|min:5|max:255" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-6" 
                              label-for="input-farm-6"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              id="input-farm-6"
                              v-model="form.pj"
                              class="form-control form-control-solid"
                              autocomplete="off"
                              required
                              size="lg"
                              placeholder="Nama PJ"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">No. Ponsel PJ</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="No. Ponsel PJ" rules="required|numeric|max:20" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-7" 
                              label-for="input-farm-7"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-input-group prepend="+62" class="border-none">
                                <b-input 
                                  autocomplete="off"
                                  id="input-farm-7" 
                                  v-model="form.pj_telp"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="No. Ponsel PJ"
                                  :state="valid"
                              ></b-input>
                              </b-input-group>
                          </b-form-group>
                          <span class="form-text text-muted">Angka nol (0) didepan tidak ditulis.</span>
                      </validationProvider>
                  </div>
              </div>

              <!--begin::Form Group-->
              <div class="separator separator-dashed my-5 mt-15"></div>
              <!--begin::Form Group-->
              <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-7">
                      <h5 class="font-weight-bold mb-6">Lokasi:</h5>
                  </div>
              </div>              
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Provinsi" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-8" 
                              label-for="input-farm-8"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-farm-8"
                              v-model="form.provinsi"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsProvinsi"
                              placeholder="Silahkan pilih Provinsi"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>                        
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Kabupaten" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-9" 
                              label-for="input-farm-9"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-farm-9"
                              v-model="form.kabupaten"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsKabupaten"
                              placeholder="Silahkan pilih Kabupaten"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>          
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Kecamatan</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Kecamatan" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-10" 
                              label-for="input-farm-10"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-farm-10"
                              v-model="form.kecamatan"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsKecamatan"
                              placeholder="Silahkan pilih Kecamatan"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Desa</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Desa" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-10" 
                              label-for="input-farm-10"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-farm-10"
                              v-model="form.desa"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsDesa"
                              placeholder="Silahkan pilih Desa"
                              :state="valid"
                              ></b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Alamat" rules="required|max:255" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-11" 
                              label-for="input-farm-11"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                              id="input-farm-11"
                              v-model="form.alamat"
                              class="form-control form-control-solid"
                              autocomplete="off"
                              required
                              size="lg"
                              placeholder="Alamat"
                              :state="valid"
                              ></b-form-input>
                          </b-form-group>
                          <span class="form-text text-muted">Tulis nama jalan, nomor rumah, nomor kompleks, nama gedung, lantai atau nomor unit.</span>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Tag Lokasi</label>
                  <div class="col-lg-9 col-xl-7">
                    <b-form-group
                        id="input-group-farm-15" 
                        label-for="gmaps"
                        invalid-feedback="Tag lokasi wajib diisi"
                        class="mb-0"
                        :state="cekgmaps">
                        <div style="display: none">
                          <input
                            id="pac-input"
                            ref="alamat"
                            class="controls"
                            type="text"
                            placeholder="Enter a location"
                          />
                        </div>

                        <div class="rounded iframe-content">
                          <div id="gmaps" ref="goomaps"></div>
                        </div>
                    </b-form-group>
                  </div>
              </div>

              <!--begin::Form Group-->
              <div class="separator separator-dashed my-5 mt-15"></div>
              <!--begin::Form Group-->
              <div class="row">
                  <label class="col-xl-3"></label>
                  <div class="col-lg-9 col-xl-7">
                      <h5 class="font-weight-bold mb-6">Rincian:</h5>
                  </div>
              </div>       
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Jml Kandang</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Jml Kandang" rules="required|min_value:1|max_value:1000000000" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-12" 
                              label-for="input-farm-12"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <vue-number-input 
                                :attrs="{ id: `input-farm-12`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                                v-model="form.kandang"
                                :min="0"
                                size="large"
                                :max="1000000000"
                                required
                                center
                                controls
                              ></vue-number-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div> 
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Jml Flock</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Jml Flock" rules="required|min_value:1|max_value:1000000000" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-13" 
                              label-for="input-farm-13"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <vue-number-input 
                                :attrs="{ id: `input-farm-13`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                                v-model="form.flock"
                                :min="0"
                                size="large"
                                :max="1000000000"
                                required
                                center
                                controls
                              ></vue-number-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Kapasitas Farm</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Kapasitas Farm" rules="required|min_value:1|max_value:1000000000" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-14" 
                              label-for="input-farm-14"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <vue-number-input 
                                :attrs="{ id: `input-farm-14`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                                v-model="form.kapasitas"
                                :min="0"
                                size="large"
                                :max="1000000000"
                                required
                                center
                                controls
                              ></vue-number-input>
                          </b-form-group>
                          <span class="form-text text-muted">Kapasitas terpasang (ekor).</span>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Jml SDM</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Jml SDM" rules="required|min_value:1|max_value:1000000000" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-farm-15" 
                              label-for="input-farm-15"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <vue-number-input 
                                :attrs="{ id: `input-farm-15`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                                v-model="form.sdm"
                                :min="0"
                                size="large"
                                :max="1000000000"
                                required
                                center
                                controls
                              ></vue-number-input>
                          </b-form-group>
                          <span class="form-text text-muted">Total SDM di Farm.</span>
                      </validationProvider>
                  </div>
              </div>

          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-9">
                <button 
                  @click="onSubmit" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-save"></i>
                  Simpan
                </button>
                &nbsp;
                <button
                  @click="onReset"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Reset
                </button>
              </div>
            </div>
          </div>
      </div>
    <!--end::Card-->
    
    </b-form>
    </ValidationObserver>
    <!--end::Form-->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import WilayahService from "@/core/services/api/master/wilayah.service";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-farm-add",
  data() {
    return {
      form: {
        nama: null,
        milik: null,
        hakmilik: null,
        sistem_kandang: null,
        pj: null,
        pj_nik: null,
        pj_telp: null,
        provinsi: null,
        kabupaten: null,
        kecamatan: null,
        desa: null,
        alamat: null,
        geotag: null,
        kandang: 0,
        flock: 0,
        kapasitas: 0,
        sdm: 0
      },
      gooMaps: null,
      gooWindow: null,
      gooMarker: null,
      opsKepemilikan: [
        { text: 'Silahkan pilih Kepemilikan', value: null },
      ],
      opsSistemKandang: [{
              value: null,
              text: "Silahkan pilih Sistem Kandang"
            }],
      opsProvinsi: [{
              value: null,
              text: "Silahkan pilih Provinsi"
            }],
      opsKabupaten: [{
              value: null,
              text: "Silahkan pilih Kabupaten"
            }],
      opsKecamatan: [{
              value: null,
              text: "Silahkan pilih Kecamatan"
            }],
      opsDesa: [{
              value: null,
              text: "Silahkan pilih Desa"
            }],
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    kepemilikan() {
      if (!this.form.milik) return 1
      const cekData = this.opsKepemilikan.find(o => o.value === this.form.milik)
      return cekData.jns
    },
    cekgmaps() {
      if (this.form.geotag) return true
      return false
    },
  },
  watch: {
    'form.milik' (val, old) {
      if (val != old) {
        this.form.hakmilik = null
      }
    },
    'form.provinsi' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.kabupaten && val != this.form.kabupaten.toString().substring(0, 2)) {
          this.form.kabupaten = null
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKabupaten({ provinsi: val, aks: 1 })
        // this.centerMaps(val, 'provinsi')
      }
    },
    'form.kabupaten' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsKecamatan= [{
            value: null,
            text: "Silahkan pilih Kecamatan"
          }]
          return
        }
        if (this.form.kecamatan && val != this.form.kecamatan.toString().substring(0, 4)) {
          this.form.kecamatan = null
          this.form.desa = null
        }
        this.getKecamatan({ kabupaten: val, aks: 1 })
        this.centerMaps(val, 'kabupaten')
      }
    },
    'form.kecamatan' (val, old) {
      if (val != old) {
        this.form.geotag = null
        if (val == null) {
          this.opsDesa= [{
            value: null,
            text: "Silahkan pilih Desa"
          }]  
          return
        }
        if (this.form.desa && val != this.form.desa.toString().substring(0, 6)) {
          this.form.desa = null
        }
        this.getDesa({ kecamatan: val, aks: 1 })
        // this.centerMaps(val, 'kecamatan')
      }
    },
    'form.desa' (val, old) {
      if (val != old) {
        this.form.geotag = null
        // this.centerMaps(val, 'desa')
      }
    }
  },
  methods: {
    centerMaps (kode, ting) {
      let latlng = {
        lat: -6.200000,
        lng: 106.816666
      }

      if (ting == 'provinsi') {
        const getDT = this.opsProvinsi.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(12);
      }
      else if (ting == 'kabupaten') {
        const getDT = this.opsKabupaten.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(13);
      }
      else if (ting == 'kecamatan') {
        const getDT = this.opsKecamatan.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(13);
      }
      else if (ting == 'desa') {
        const getDT = this.opsDesa.find(o => o.value === kode)
        latlng = {
          lat: parseFloat(getDT.lat),
          lng: parseFloat(getDT.lng)
        }
        this.gooMaps.setZoom(13);
      }
      else {
        latlng = { lat: -6.200000, lng: 106.816666 }
        this.gooMaps.setZoom(11);
      }

      this.gooMaps.setCenter(latlng);
    },
    getKepemilikan() {
      MasterService.getKepemilikan()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKepemilikan = [{
                  value: null,
                  text: "Silahkan pilih Kepemilikan"
                }]
                return;
            }

            this.opsKepemilikan = res.data.data
            this.opsKepemilikan.push({
              value: null,
              text: "Silahkan pilih Kepemilikan"
            })
        })
        .catch((err) => {
          this.opsKepemilikan = [{
            value: null,
            text: "Tidak ada Kepemilikan"
          }]
          ErrorService.status(err)
        })
    },
    getSistemKandang() {
      MasterService.getSistemKandang()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSistemKandang = [{
                  value: null,
                  text: "Silahkan pilih Sistem Kandang"
                }]
                return;
            }

            this.opsSistemKandang = res.data.data
            this.opsSistemKandang.push({
              value: null,
              text: "Silahkan pilih Sistem Kandang"
            })
        })
        .catch((err) => {
          this.opsSistemKandang = [{
            value: null,
            text: "Tidak ada Sistem Kandang"
          }]
          ErrorService.status(err)
        })
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan pilih Provinsi"
            })
        })
        .catch((err) => {
          this.opsProvinsi = [{
            value: null,
            text: "Tidak ada Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getKabupaten(param) {
      if (!param) {
        this.opsKabupaten = [{
          value: null,
          text: "Silahkan pilih Kabupaten"
        }]
        return;
      }
      
      WilayahService.getKabupaten(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKabupaten = [{
                  value: null,
                  text: "Silahkan pilih Kabupaten"
                }]
                return;
            }

            this.opsKabupaten = res.data.data
            this.opsKabupaten.push({
              value: null,
              text: "Silahkan pilih Kabupaten"
            })
        })
        .catch((err) => {
          this.opsKabupaten = [{
            value: null,
            text: "Tidak ada Kabupaten"
          }]
          ErrorService.status(err)
        })
    },
    getKecamatan(param) {
      if (!param) {
        this.opsKecamatan = [{
          value: null,
          text: "Silahkan pilih Kecamatan"
        }]
        return;
      }

      WilayahService.getKecamatan(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKecamatan = [{
                  value: null,
                  text: "Silahkan pilih Kecamatan"
                }]
                return;
            }

            this.opsKecamatan = res.data.data
            this.opsKecamatan.push({
              value: null,
              text: "Silahkan pilih Kecamatan"
            })
        })
        .catch((err) => {
          this.opsKecamatan = [{
            value: null,
            text: "Tidak ada Kecamatan"
          }]
          ErrorService.status(err)
        })
    },
    getDesa(param) {
      if (!param) {
        this.opsDesa = [{
          value: null,
          text: "Silahkan pilih Desa"
        }]
        return;
      }

      WilayahService.getDesa(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsDesa = [{
                  value: null,
                  text: "Silahkan pilih Desa"
                }]
                return;
            }

            this.opsDesa = res.data.data
            this.opsDesa.push({
              value: null,
              text: "Silahkan pilih Desa"
            })
        })
        .catch((err) => {
          this.opsDesa = [{
            value: null,
            text: "Tidak ada Desa"
          }]
          ErrorService.status(err)
        })
    },
    initMaps() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this.gooMaps = new google.maps.Map(this.$refs.goomaps, {
            center: {
              lat: -6.200000,
              lng: 106.816666
            },
            zoom: 11,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },
            streetViewControl: false

          });

          this.gooWindow = new google.maps.InfoWindow({ content: "Lokasi dipilih" });
          this.gooMarker = new google.maps.Marker({ map: this.gooMaps });

          const input = this.$refs.alamat;
          this.gooMaps.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          const autocomplete = new google.maps.places.Autocomplete(input);
          autocomplete.bindTo("bounds", this.gooMaps);
          autocomplete.setFields(["place_id", "geometry"]);


          this.gooMaps.addListener("click", (mapsMouseEvent) => {
            this.gooWindow.close();
            this.gooMarker.setMap(null);

            this.gooMarker = new google.maps.Marker({ 
              position: mapsMouseEvent.latLng,
              map: this.gooMaps 
            });
            
            this.gooMarker.setVisible(true);
            this.gooWindow.open(this.gooMaps, this.gooMarker);

            this.form.geotag = mapsMouseEvent.latLng.toJSON()
          });

          autocomplete.addListener("place_changed", () => {
            this.gooWindow.close();
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
              return;
            }

            if (place.geometry.viewport) {
              this.gooMaps.fitBounds(place.geometry.viewport);
            } else {
              this.gooMaps.setCenter(place.geometry.location);
              this.gooMaps.setZoom(17);
            }
            
            this.gooMarker.setPlace({
              placeId: place.place_id,
              location: place.geometry.location,
            });

            this.gooMarker.setVisible(true);
            this.gooWindow.open(this.gooMaps, this.gooMarker);
            
            this.form.geotag = place.geometry.location.toJSON()
          });

        })
        .catch(err => {
          ErrorService.message({
            action: 'Peringatan',
            message: 'Maps gagal dimuat',
          })
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            PerusahaanService.addPerusahaanFarm(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$router.push({ name: 'master-data-farm-list' })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form.nama = null
      this.form.sistem_kandang = null
      this.form.pj = null
      this.form.pj_nik = null
      this.form.pj_telp = null
      this.form.provinsi = null
      this.form.kabupaten = null
      this.form.kecamatan = null
      this.form.desa = null
      this.form.alamat = null
      this.form.geotag = null
      this.form.kandang = 0
      this.form.flock = 0
      this.form.kapasitas = 0
      this.form.sdm = 0      
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
        setTimeout(() => {
          this.initMaps()        
        }, 500);
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Farm" }]);
    if (!this.currentUser.menu.farm) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getKepemilikan()
    this.getProvinsi()
    this.getSistemKandang()
    this.initMaps()
  },
};
</script>
<style lang="scss">
  #master-data-farm-add {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps {
        height: 100%;
      }
    }

    .controls {
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid transparent;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 300;
      height: 29px;
      margin-top: 10px;
      outline: none;
      padding: 0 11px 0 13px;
      text-overflow: ellipsis;
    }

    .controls:focus {
      border-color: #4d90fe;
    }

  }
</style>